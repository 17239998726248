export default class NestorTriggerComponent {

	constructor() {
		NestorTriggerComponent.removeInIframe();
	}

	static removeInIframe() {
		if (window.self !== window.top) {
			const nestorDiv = document.querySelector('[data-element="nestor-trigger"]');
			if (nestorDiv) {
				nestorDiv.remove();
			}
		}
	}
}
